import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.string.link.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    class: {
      'd-flex align-center': _vm.horizontal
    },
    style: _setup.style
  }, [_c('SmallLabel', {
    class: {
      'mb-1': !_vm.xSmall && !_vm.horizontal,
      'mr-2': _vm.horizontal
    },
    staticStyle: {
      "white-space": "nowrap"
    },
    attrs: {
      "label": _vm.label,
      "x-small": _vm.xSmall
    }
  }, [_vm._t("label.append")], 2), _vm._v(" "), _vm.$slots.default ? [_vm._t("default")] : [_vm.dummy ? _c('SkeletonLoader', {
    attrs: {
      "text": "",
      "width": _vm.dummyWidth
    }
  }) : _vm.text ? [_vm.link ? _c('LinkText', {
    class: {
      'text-body-1': !_vm.xSmall,
      'text-body-2': _vm.xSmall
    },
    attrs: {
      "to": _vm.link,
      "text": _vm.text
    }
  }) : _c('div', {
    class: _defineProperty({
      'text-body-1': !_vm.xSmall,
      'text-body-2': _vm.xSmall
    }, "".concat(_vm.textColor, "--text"), _vm.textColor)
  }, [_vm._v("\n        " + _vm._s(_vm.text) + "\n      ")])] : _c('div', {
    staticClass: "text-body-2 disabled-text--text"
  }, [_vm._v("\n      " + _vm._s(_vm.$t('notSet')) + "\n    ")])]], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('h6', [_vm._v(_vm._s(_vm.$t('settings.accountTab')))]), _vm._v(" "), _c('VerticalTable', {
    staticClass: "mt-4",
    attrs: {
      "headers": [{
        text: _vm.$t('name'),
        value: 'user.name',
        getter: function getter(val) {
          return _vm.$fullName(val.user);
        }
      }, {
        text: _vm.$t('email'),
        value: 'user.email'
      }, {
        text: _vm.$t('accountName'),
        value: 'company.company_name'
      }],
      "item": {
        user: _vm.user,
        company: _vm.company
      }
    }
  }), _vm._v(" "), _c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('h6', {
    staticClass: "mb-1"
  }, [_vm._v(_vm._s(_vm.$t('settings.notification.emailSetting')))]), _vm._v(" "), _c('div', {
    staticClass: "text-caption secondary-text--text"
  }, [_vm._v("\n    " + _vm._s(_vm.$t('settings.notification.emailSettingDesc')) + "\n  ")]), _vm._v(" "), _c('SmallLabel', {
    staticClass: "mt-4",
    attrs: {
      "label": _vm.$t('settings.notification.orderStatus')
    }
  }), _vm._v(" "), _c(VRow, {
    attrs: {
      "no-gutters": ""
    }
  }, _vm._l(_vm.orderStatus, function (item) {
    return _c(VCol, {
      key: item.id,
      attrs: {
        "cols": "12",
        "sm": "6",
        "md": "4",
        "lg": "3"
      }
    }, [_c(VCheckbox, {
      staticClass: "mt-4",
      attrs: {
        "value": item.id,
        "hide-details": ""
      },
      on: {
        "change": _vm.saveSubscriptions
      },
      scopedSlots: _vm._u([{
        key: "label",
        fn: function fn() {
          return [_vm._v("\n          " + _vm._s(item.name) + "\n          "), item.info ? _c('FloatingTooltip', {
            attrs: {
              "right": "",
              "max-width": "300px"
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function fn(_ref) {
                var on = _ref.on,
                  attrs = _ref.attrs;
                return [_c(VIcon, _vm._g(_vm._b({
                  staticClass: "ml-1",
                  attrs: {
                    "small": ""
                  }
                }, 'v-icon', attrs, false), on), [_vm._v("\n                mdi-information\n              ")])];
              }
            }], null, true)
          }, [_vm._v(" "), _c('span', {
            staticClass: "text-body-2"
          }, [_vm._v(_vm._s(item.info))])]) : _vm._e()];
        },
        proxy: true
      }], null, true),
      model: {
        value: _vm.selectStatus,
        callback: function callback($$v) {
          _vm.selectStatus = $$v;
        },
        expression: "selectStatus"
      }
    })], 1);
  }), 1), _vm._v(" "), _c('SmallLabel', {
    staticClass: "mt-4",
    attrs: {
      "label": _vm.$t('settings.notification.orderApprovalStatus')
    }
  }), _vm._v(" "), _c(VRow, {
    attrs: {
      "no-gutters": ""
    }
  }, _vm._l(_vm.orderApprovalStatus, function (item) {
    return _c(VCol, {
      key: item.id,
      attrs: {
        "cols": "12",
        "sm": "6",
        "md": "4",
        "lg": "3"
      }
    }, [_c(VCheckbox, {
      staticClass: "mt-4",
      attrs: {
        "label": item.name,
        "value": item.id,
        "hide-details": ""
      },
      on: {
        "change": _vm.saveSubscriptions
      },
      model: {
        value: _vm.selectStatus,
        callback: function callback($$v) {
          _vm.selectStatus = $$v;
        },
        expression: "selectStatus"
      }
    })], 1);
  }), 1), _vm._v(" "), _c('SmallLabel', {
    staticClass: "mt-4",
    attrs: {
      "label": _vm.$t('settings.notification.contactReaction')
    }
  }), _vm._v(" "), _c(VRow, {
    attrs: {
      "no-gutters": ""
    }
  }, _vm._l(_vm.contactReactions, function (item) {
    return _c(VCol, {
      key: item.id
    }, [_c(VCheckbox, {
      staticClass: "mt-4",
      attrs: {
        "label": item.name,
        "value": item.id,
        "hide-details": ""
      },
      on: {
        "change": _vm.saveSubscriptions
      },
      model: {
        value: _vm.selectStatus,
        callback: function callback($$v) {
          _vm.selectStatus = $$v;
        },
        expression: "selectStatus"
      }
    })], 1);
  }), 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
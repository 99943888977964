/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./TeamInfoTab.vue?vue&type=template&id=20d47adc&scoped=true"
import script from "./TeamInfoTab.vue?vue&type=script&lang=js"
export * from "./TeamInfoTab.vue?vue&type=script&lang=js"
import style0 from "./TeamInfoTab.vue?vue&type=style&index=0&id=20d47adc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20d47adc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SmallLabel: require('/code/src/components/text/SmallLabel.vue').default,TextButton: require('/code/src/components/button/TextButton.vue').default,FloatingTooltip: require('/code/src/components/tooltip/FloatingTooltip.vue').default,OutlinedTable: require('/code/src/components/table/OutlinedTable.vue').default,LabelText: require('/code/src/components/text/LabelText.vue').default})

import { VCardText } from 'vuetify/lib/components/VCard';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(_setup.SimpleDialog, {
    ref: "dialogRef",
    attrs: {
      "title": _setup.$t('settings.memberAdd.title'),
      "actions": {
        false: _setup.$t('action.cancel'),
        true: _setup.$t('action.add')
      },
      "valid": _setup.isValid,
      "loading": _setup.loading,
      "before-close": _setup.beforeClose,
      "persistent": ""
    },
    on: {
      "click": _setup.onClick,
      "close": function close($event) {
        return _setup.emits('close');
      }
    }
  }, [_c(VCardText, {
    staticClass: "py-2"
  }, [_c('Input', {
    attrs: {
      "initial-value": _setup.initialValue.email,
      "label": _setup.$t('email'),
      "required": "",
      "rules": [function (v) {
        return _setup.$isValidEmail(v) || _setup.$t('error.email');
      }]
    },
    model: {
      value: _setup.email,
      callback: function callback($$v) {
        _setup.email = $$v;
      },
      expression: "email"
    }
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
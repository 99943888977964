import IndexTab from './tabs/IndexTab.vue';
import TeamInfoTab from './tabs/TeamInfoTab.vue';
import MessageTemplateTab from './tabs/MessageTemplateTab.vue';
export default {
  components: {
    IndexTab: IndexTab,
    TeamInfoTab: TeamInfoTab,
    MessageTemplateTab: MessageTemplateTab
  },
  layout: 'dashboard',
  head: function head() {
    return {
      title: this.$t('settings.title')
    };
  }
};
/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./IndexTab.vue?vue&type=template&id=a71c74ba"
import script from "./IndexTab.vue?vue&type=script&lang=js"
export * from "./IndexTab.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VerticalTable: require('/code/src/components/table/VerticalTable.vue').default,SmallLabel: require('/code/src/components/text/SmallLabel.vue').default,FloatingTooltip: require('/code/src/components/tooltip/FloatingTooltip.vue').default})

import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.values.js";
var render = function render() {
  var _vm$currentTeam, _vm$currentTeam2, _vm$currentTeam3, _vm$company$image, _vm$company, _vm$company2;
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('h6', [_vm._v(_vm._s(_vm.$t('teamInfo.title')))]), _vm._v(" "), _c('SmallLabel', {
    staticClass: "mt-4 mb-2",
    attrs: {
      "label": _vm.$t('teamInfo.affiliatedTeam')
    }
  }), _vm._v(" "), _c(VCard, {
    staticClass: "pa-4",
    attrs: {
      "elevation": "0",
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('div', [_c('div', {
    staticClass: "text-subtitle-1"
  }, [_vm._v(_vm._s((_vm$currentTeam = _vm.currentTeam) === null || _vm$currentTeam === void 0 ? void 0 : _vm$currentTeam.name))]), _vm._v(" "), (_vm$currentTeam2 = _vm.currentTeam) !== null && _vm$currentTeam2 !== void 0 && _vm$currentTeam2.description ? _c('div', {
    staticClass: "text-body-2 secondary-text--text mt-2",
    domProps: {
      "textContent": _vm._s((_vm$currentTeam3 = _vm.currentTeam) === null || _vm$currentTeam3 === void 0 ? void 0 : _vm$currentTeam3.description)
    }
  }) : _vm._e()]), _vm._v(" "), _c(VSpacer), _vm._v(" "), _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('FloatingTooltip', {
    attrs: {
      "top": "",
      "disabled": _vm.canTeamEdit
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('div', _vm._g(_vm._b({}, 'div', attrs, false), on), [_c('TextButton', {
          attrs: {
            "value": _vm.$t('teamInfo.editTeam'),
            "icon": "mdi-pencil-outline",
            "disabled": !_vm.canTeamEdit
          },
          on: {
            "click": _vm.editTeam
          }
        })], 1)];
      }
    }])
  }, [_vm._v(" "), _c('span', {
    staticClass: "text-body-2"
  }, [_vm._v("\n            " + _vm._s(_vm.$t('permissions.noPermission')) + "\n          ")])]), _vm._v(" "), _c(VDivider, {
    staticClass: "mx-4",
    attrs: {
      "vertical": ""
    }
  }), _vm._v(" "), _c('TextButton', {
    attrs: {
      "value": _vm.$t('teamInfo.switchTeam'),
      "icon": "mdi-account-multiple-outline",
      "to": {
        path: '/teams/select',
        query: {
          next: _vm.$router.currentRoute.path
        }
      }
    }
  })], 1)], 1)]), _vm._v(" "), _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('SmallLabel', {
    staticClass: "mt-4 mb-2",
    attrs: {
      "label": _vm.$t('teamInfo.members')
    }
  }), _vm._v(" "), _c(VSpacer), _vm._v(" "), _c('FloatingTooltip', {
    attrs: {
      "top": "",
      "disabled": _vm.canTeamEdit
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c('div', _vm._g(_vm._b({}, 'div', attrs, false), on), [_c('TextButton', {
          attrs: {
            "value": _vm.$t('teamInfo.addMember'),
            "icon": "mdi-account-plus-outline",
            "disabled": !_vm.canTeamEdit
          },
          on: {
            "click": _vm.addMember
          }
        })], 1)];
      }
    }])
  }, [_vm._v(" "), _c('span', {
    staticClass: "text-body-2"
  }, [_vm._v(_vm._s(_vm.$t('permissions.noPermission')))])])], 1), _vm._v(" "), _c('OutlinedTable', {
    attrs: {
      "headers": [{
        text: '氏名',
        value: 'name'
      }, {
        text: 'メール',
        value: 'email'
      }, {
        text: '権限',
        value: 'role',
        ellipsis: false
      }, {
        text: '',
        value: 'can_remove',
        sortable: false,
        align: 'center'
      }],
      "items": _vm.users,
      "item-key": "id",
      "options": _vm.tableOptions
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.tableOptions = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v("\n      " + _vm._s(_vm.$fullName(item)) + "\n    ")];
      }
    }, {
      key: "item.role",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c(VSelect, {
          staticClass: "small mb-1 text-body-2",
          attrs: {
            "items": _vm.editableRoles(item.role),
            "readonly": !item.role_editable,
            "append-icon": item.role_editable ? 'mdi-chevron-down' : null,
            "hide-details": "",
            "dense": "",
            "menu-props": {
              offsetY: true,
              closeOnClick: true
            }
          },
          on: {
            "change": function change($event) {
              return _vm.roleChanged(item);
            }
          },
          model: {
            value: item.role,
            callback: function callback($$v) {
              _vm.$set(item, "role", $$v);
            },
            expression: "item.role"
          }
        })];
      }
    }, {
      key: "item.can_remove",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [item.can_remove ? _c('TextButton', {
          attrs: {
            "color": "error",
            "value": _vm.$t('action.delete')
          },
          nativeOn: {
            "click": function click($event) {
              return _vm.removeMember(item);
            }
          }
        }) : _vm._e()];
      }
    }])
  }), _vm._v(" "), _c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('h6', {
    staticClass: "mb-4"
  }, [_vm._v(_vm._s(_vm.$t('teamInfo.integrations')))]), _vm._v(" "), _c(VRow, _vm._l(['salesforce', 'hubspot', 'slack', 'chatwork', 'teams'], function (name) {
    var _vm$svgs$name, _vm$integrations$name;
    return _c(VCol, {
      key: name,
      staticStyle: {
        "max-width": "280px"
      },
      attrs: {
        "cols": "12",
        "sm": "6",
        "md": "6",
        "lg": "3"
      }
    }, [(_vm.syncable = ['salesforce', 'hubspot'].includes(name)) ? void 0 : _vm._e(), _vm._v(" "), _c(VCard, {
      attrs: {
        "elevation": "0",
        "outlined": "",
        "to": "/settings/integration/".concat(name)
      }
    }, [_vm.syncable && !_vm.loading.integrations ? _c('FloatingTooltip', {
      attrs: {
        "top": "",
        "hoverable": "",
        "max-width": "260px"
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref6) {
          var on = _ref6.on,
            attrs = _ref6.attrs;
          return [_c(VAvatar, _vm._g(_vm._b({
            staticClass: "p-absolute",
            staticStyle: {
              "top": "8px",
              "right": "8px",
              "z-index": "1"
            },
            attrs: {
              "color": _vm.indicatorColor(name),
              "size": "9"
            }
          }, 'v-avatar', attrs, false), on))];
        }
      }], null, true)
    }, [_vm._v(" "), _c('span', {
      staticClass: "text-body-2",
      domProps: {
        "textContent": _vm._s(_vm.indicatorTooltip(name))
      }
    })]) : _vm._e(), _vm._v(" "), _c(VResponsive, {
      staticClass: "pa-4 mx-auto",
      attrs: {
        "aspect-ratio": 4 / 3,
        "content-class": "align-self-center",
        "max-width": "240",
        "width": "100%"
      }
    }, [_c((_vm$svgs$name = _vm.svgs[name]) !== null && _vm$svgs$name !== void 0 ? _vm$svgs$name : 'div', {
      tag: "component"
    })], 1), _vm._v(" "), _c('div', {
      staticClass: "px-4 pb-2"
    }, [_c('LabelText', {
      attrs: {
        "label": _vm.syncable ? _vm.$t('teamInfo.activitySync') : _vm.$t('teamInfo.activityNotice'),
        "text": _vm.integrations[name].is_active ? _vm.$t('valid') : _vm.$t('invalid'),
        "dummy": _vm.loading.integrations,
        "horizontal": "",
        "x-small": "",
        "dummy-width": "30",
        "text-color": _vm.integrations[name].is_active ? null : 'disabled-text'
      }
    }), _vm._v(" "), (_vm.mappingActive = Object.values((_vm$integrations$name = _vm.integrations[name].is_mapping_active) !== null && _vm$integrations$name !== void 0 ? _vm$integrations$name : {}).some(function (it) {
      return it === true;
    })) ? void 0 : _vm._e(), _vm._v(" "), _c('LabelText', {
      style: "visibility: ".concat(_vm.syncable ? 'visible' : 'hidden'),
      attrs: {
        "label": _vm.$t('sync'),
        "text": _vm.mappingActive ? _vm.$t('valid') : _vm.$t('invalid'),
        "dummy": _vm.loading.integrations,
        "horizontal": "",
        "x-small": "",
        "dummy-width": "30",
        "text-color": _vm.mappingActive ? null : 'disabled-text'
      }
    })], 2), _vm._v(" "), _c('TextButton', {
      staticClass: "p-absolute",
      staticStyle: {
        "right": "8px",
        "bottom": "8px",
        "z-index": "1"
      },
      attrs: {
        "value": _vm.$t('action.setting'),
        "to": "/settings/integration/".concat(name)
      }
    })], 1)], 2);
  }), 1), _vm._v(" "), _c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('h6', {
    staticClass: "mb-4"
  }, [_vm._v(_vm._s(_vm.$t('teamInfo.companyInfo')))]), _vm._v(" "), _c('div', {
    staticClass: "align-center",
    class: {
      'd-flex': _vm.$vuetify.breakpoint.mdAndUp,
      'mb-4': !_vm.$vuetify.breakpoint.mdAndUp
    }
  }, [_c(VCard, {
    staticClass: "flex-shrink-0",
    attrs: {
      "elevation": "0",
      "outlined": "",
      "min-width": 175
    }
  }, [_c(VImg, {
    attrs: {
      "src": (_vm$company$image = (_vm$company = _vm.company) === null || _vm$company === void 0 ? void 0 : _vm$company.image) !== null && _vm$company$image !== void 0 ? _vm$company$image : _vm.defaultImage,
      "aspect-ratio": 175 / 80,
      "contain": ""
    }
  })], 1), _vm._v(" "), _c('div', {
    class: {
      'ml-4': _vm.$vuetify.breakpoint.mdAndUp,
      'mt-4': !_vm.$vuetify.breakpoint.mdAndUp
    }
  }, [_c('LabelText', {
    attrs: {
      "label": _vm.$t('accountName'),
      "text": (_vm$company2 = _vm.company) === null || _vm$company2 === void 0 ? void 0 : _vm$company2.company_name,
      "horizontal": _vm.$vuetify.breakpoint.mdAndUp
    }
  }), _vm._v(" "), _c('LabelText', {
    class: {
      'mt-1': _vm.$vuetify.breakpoint.mdAndUp,
      'mt-2': !_vm.$vuetify.breakpoint.mdAndUp
    },
    attrs: {
      "label": _vm.$t('teamInfo.billingAddress'),
      "horizontal": _vm.$vuetify.breakpoint.mdAndUp
    }
  }, [_c('div', {
    staticClass: "d-flex align-baseline"
  }, [_vm.$address(_vm.billingAddress) ? _c('div', {
    staticClass: "text-body-1"
  }, [_vm._v("\n            " + _vm._s(_vm.$address(_vm.billingAddress)) + "\n          ")]) : _c('div', {
    staticClass: "text-body-2 disabled-text--text"
  }, [_vm._v("\n            " + _vm._s(_vm.$t('notSet')) + "\n          ")]), _vm._v(" "), _c('FloatingTooltip', {
    attrs: {
      "top": "",
      "disabled": _vm.canTeamEdit
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref7) {
        var on = _ref7.on,
          attrs = _ref7.attrs;
        return [_c('div', _vm._g(_vm._b({}, 'div', attrs, false), on), [_c('TextButton', {
          staticClass: "text-subtitle-2 ml-2",
          attrs: {
            "value": _vm.$t('action.edit'),
            "disabled": !_vm.canTeamEdit
          },
          on: {
            "click": _vm.editBilling
          }
        })], 1)];
      }
    }])
  }, [_vm._v(" "), _c('span', {
    staticClass: "text-body-2"
  }, [_vm._v("\n              " + _vm._s(_vm.$t('permissions.noPermission')) + "\n            ")])])], 1)])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
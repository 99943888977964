import { VCardText } from 'vuetify/lib/components/VCard';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(_setup.SimpleDialog, {
    ref: "dialogRef",
    attrs: {
      "title": _setup.$t('settings.billingEdit.title'),
      "actions": {
        false: _setup.$t('action.cancel'),
        true: _setup.$t('action.save')
      },
      "valid": _setup.isValid,
      "loading": _setup.loading,
      "before-close": _setup.beforeClose,
      "persistent": ""
    },
    on: {
      "click": _setup.onClick,
      "close": function close($event) {
        return _setup.emits('close');
      }
    }
  }, [_c(VCardText, {
    staticClass: "py-2"
  }, [_c('ZipcodeInput', {
    staticClass: "mt-4",
    attrs: {
      "initial-value": _setup.initialValue.postcode,
      "label": _setup.$t('postcode'),
      "placeholder": _setup.$t('placeholder.postcode'),
      "required": ""
    },
    on: {
      "update": function update($event) {
        _setup.pref = $event.pref;
        _setup.city = $event.city;
      }
    },
    model: {
      value: _setup.postcode,
      callback: function callback($$v) {
        _setup.postcode = $$v;
      },
      expression: "postcode"
    }
  }), _vm._v(" "), _c('Input', {
    staticClass: "mt-4",
    attrs: {
      "initial-value": _setup.initialValue.pref,
      "type": "select",
      "label": _setup.$t('pref'),
      "placeholder": _setup.$t('placeholder.pref'),
      "items": _setup.$prefsForSelect,
      "required": ""
    },
    model: {
      value: _setup.pref,
      callback: function callback($$v) {
        _setup.pref = $$v;
      },
      expression: "pref"
    }
  }), _vm._v(" "), _c('Input', {
    staticClass: "mt-4",
    attrs: {
      "initial-value": _setup.initialValue.city,
      "label": _setup.$t('city'),
      "placeholder": _setup.$t('placeholder.city'),
      "required": ""
    },
    model: {
      value: _setup.city,
      callback: function callback($$v) {
        _setup.city = $$v;
      },
      expression: "city"
    }
  }), _vm._v(" "), _c('Input', {
    staticClass: "mt-4",
    attrs: {
      "initial-value": _setup.initialValue.street1,
      "label": _setup.$t('street1'),
      "placeholder": _setup.$t('placeholder.street1'),
      "required": ""
    },
    model: {
      value: _setup.street1,
      callback: function callback($$v) {
        _setup.street1 = $$v;
      },
      expression: "street1"
    }
  }), _vm._v(" "), _c('Input', {
    staticClass: "mt-4",
    attrs: {
      "initial-value": _setup.initialValue.street2,
      "label": _setup.$t('street2'),
      "placeholder": _setup.$t('placeholder.street2')
    },
    model: {
      value: _setup.street2,
      callback: function callback($$v) {
        _setup.street2 = $$v;
      },
      expression: "street2"
    }
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
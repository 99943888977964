import { VContainer } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, {
    attrs: {
      "fluid": ""
    }
  }, [_c(VToolbar, {
    staticClass: "elevation-0 transparent media-toolbar"
  }, [_c(VToolbarTitle, [_c('h5', [_vm._v(_vm._s(_vm.$t('settings.title')))])]), _vm._v(" "), _c(VSpacer), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-outline-primary",
    attrs: {
      "type": "button"
    },
    domProps: {
      "textContent": _vm._s(_vm.$t('logout'))
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          path: '/logout'
        });
      }
    }
  })], 1), _vm._v(" "), _c('ContentArea', [_c(VTabs, [_c(VTab, {
    attrs: {
      "nuxt": "",
      "to": "/settings",
      "exact": ""
    }
  }, [_vm._v(_vm._s(_vm.$t('settings.accountTab')))]), _vm._v(" "), _c(VTab, {
    attrs: {
      "nuxt": "",
      "to": "/settings/team-info",
      "exact": ""
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('settings.teamInfoTab')) + "\n      ")]), _vm._v(" "), _c(VTab, {
    attrs: {
      "nuxt": "",
      "to": "/settings/message-template",
      "exact": ""
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('settings.messageTemplateTab')) + "\n      ")]), _vm._v(" "), _c(VTabItem, {
    attrs: {
      "value": "/settings",
      "transition": false,
      "reverse-transition": false
    }
  }, [_c('IndexTab')], 1), _vm._v(" "), _c(VTabItem, {
    attrs: {
      "value": "/settings/team-info",
      "transition": false,
      "reverse-transition": false
    }
  }, [_c('TeamInfoTab')], 1), _vm._v(" "), _c(VTabItem, {
    attrs: {
      "value": "/settings/message-template",
      "transition": false,
      "reverse-transition": false
    }
  }, [_c('MessageTemplateTab')], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
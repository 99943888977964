import { VCardText } from 'vuetify/lib/components/VCard';

import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(_setup.SimpleDialog, {
    ref: "dialogRef",
    attrs: {
      "title": _setup.$t('settings.messageTemplateTab'),
      "actions": {
        false: _setup.$t('action.cancel'),
        true: _setup.props.templateId ? _setup.$t('action.save') : _setup.$t('action.add')
      },
      "valid": _setup.isValid,
      "loading": _setup.loading,
      "before-close": _setup.beforeClose,
      "persistent": "",
      "width": "600px"
    },
    on: {
      "click": _setup.onClick,
      "close": function close($event) {
        return _setup.emits('close');
      }
    }
  }, [_c(VCardText, {
    staticClass: "py-2"
  }, [_c('Input', {
    staticClass: "mt-4",
    attrs: {
      "initial-value": _setup.initialValue.name,
      "label": _setup.$t('messageTemplate.name'),
      "required": "",
      "clearable": ""
    },
    model: {
      value: _setup.name,
      callback: function callback($$v) {
        _setup.name = $$v;
      },
      expression: "name"
    }
  }), _vm._v(" "), _c('Input', {
    staticClass: "mt-4",
    attrs: {
      "initial-value": _setup.initialValue.template,
      "label": _setup.$t('messageTemplate.description'),
      "type": "multiLine",
      "required": ""
    },
    on: {
      "contextmenu": function contextmenu($event) {
        var _setup$contextMenuRef;
        $event.preventDefault();
        (_setup$contextMenuRef = _setup.contextMenuRef) === null || _setup$contextMenuRef === void 0 ? void 0 : _setup$contextMenuRef.show($event);
      }
    },
    model: {
      value: _setup.template,
      callback: function callback($$v) {
        _setup.template = $$v;
      },
      expression: "template"
    }
  }), _vm._v(" "), _c(_setup.TextEditorContextMenu, {
    ref: "contextMenuRef",
    attrs: {
      "template": false
    }
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t('messageTemplate.title')))]), _vm._v(" "), _c(VSpacer), _vm._v(" "), _c('FloatingTooltip', {
    attrs: {
      "top": "",
      "disabled": _vm.canTemplateEdit
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('div', _vm._g(_vm._b({}, 'div', attrs, false), on), [_c('button', {
          staticClass: "tw-btn tw-btn-fill-primary",
          attrs: {
            "type": "button",
            "disabled": !_vm.canTemplateEdit
          },
          on: {
            "click": _vm.onClickEdit
          }
        }, [_vm._v("\n            " + _vm._s(_vm.$t('action.create')) + "\n          ")])])];
      }
    }])
  }, [_vm._v(" "), _c('span', {
    staticClass: "text-body-2"
  }, [_vm._v(_vm._s(_vm.$t('permissions.noPermission')))])])], 1), _vm._v(" "), _c('Input', {
    staticClass: "mt-4 mb-6",
    attrs: {
      "label": _vm.$t('keyword'),
      "clearable": "",
      "prepend-inner-icon": "mdi-magnify",
      "placeholder": _vm.$t('placeholder.messageTemplate')
    },
    model: {
      value: _vm.keywordText,
      callback: function callback($$v) {
        _vm.keywordText = $$v;
      },
      expression: "keywordText"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "p-relative",
    staticStyle: {
      "min-height": "194px"
    }
  }, [_vm.loading.fetchTemplate ? _c('Loading') : _vm.templateTexts.length ? _c(VRow, _vm._l(_vm.templateTexts, function (item) {
    return _c(VCol, {
      key: item.id,
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c(VCard, {
      staticClass: "pa-4",
      attrs: {
        "elevation": "0",
        "outlined": ""
      }
    }, [_c('div', {
      staticClass: "d-flex mb-2"
    }, [_c('div', {
      staticClass: "text-subtitle-2"
    }, [_vm._v(_vm._s(item.name))]), _vm._v(" "), _c(VSpacer), _vm._v(" "), _c('FloatingTooltip', {
      attrs: {
        "top": "",
        "disabled": _vm.canTemplateEdit
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref2) {
          var on = _ref2.on,
            attrs = _ref2.attrs;
          return [_c('div', _vm._g(_vm._b({
            staticClass: "d-flex"
          }, 'div', attrs, false), on), [_c('TextButton', {
            attrs: {
              "disabled": !_vm.canTemplateEdit,
              "color": "error",
              "value": _vm.$t('action.delete')
            },
            on: {
              "click": function click($event) {
                return _vm.onClickDelete(item);
              }
            }
          })], 1), _vm._v(" "), _c(VDivider, {
            staticClass: "mx-2 my-1",
            attrs: {
              "vertical": ""
            }
          }), _vm._v(" "), _c('div', _vm._g(_vm._b({
            staticClass: "d-flex"
          }, 'div', attrs, false), on), [_c('TextButton', {
            attrs: {
              "disabled": !_vm.canTemplateEdit,
              "value": _vm.$t('action.edit')
            },
            on: {
              "click": function click($event) {
                return _vm.onClickEdit(item);
              }
            }
          })], 1)];
        }
      }], null, true)
    }, [_vm._v(" "), _c('span', {
      staticClass: "text-body-2"
    }, [_vm._v("\n                " + _vm._s(_vm.$t('permissions.noPermission')) + "\n              ")])])], 1), _vm._v(" "), _c('div', {
      staticClass: "color-background rounded pa-2"
    }, [_c('pre', {
      directives: [{
        name: "ellipsis",
        rawName: "v-ellipsis",
        value: 4,
        expression: "4"
      }],
      staticClass: "text-caption secondary-text--text",
      staticStyle: {
        "white-space": "pre-wrap"
      },
      domProps: {
        "textContent": _vm._s(item.template)
      }
    })])])], 1);
  }), 1) : _c('div', {
    staticClass: "pt-16 text-body-1 text-center"
  }, [_vm.keywordText ? [_vm._v("\n        " + _vm._s(_vm.$t('messageTemplate.filteredEmpty')) + "\n      ")] : [_vm._v("\n        " + _vm._s(_vm.$t('messageTemplate.empty')) + "\n      ")]], 2)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };